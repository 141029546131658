import { Injectable, signal } from '@angular/core';

// TODO move this
interface IUIMessage {
  objectId: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class MessagesUiService {
  private readonly _messages = signal<IUIMessage[]>([]);

  constructor() {}

  public getMessagesForObjectId(objectId: string): string[] {
    return this._messages()
      .filter((msg) => msg.objectId === objectId)
      .map((msg) => msg.message);
  }

  public setMessage(objectId: string, message: string): void {
    this._messages.update((messages) => [...messages, { objectId, message }]);
  }

  public clearMessages(): void {
    this._messages.set([]);
  }
}
